
	.home-btns {
		.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
			background-color: rgb(253, 226, 226);
			color: #F56C6C;
		}
	}
	.banner-blk {
		position: relative;
	}
	/*.fixed-header {*/
	/*	!*position: absolute;*!*/
	/*	height: 4.5rem;*/
	/*	width: 100%;*/
	/*	top: 0.8125rem;*/
	/*	left: 0;*/
	/*	display: flex;*/
	/*	justify-content: center;*/
	/*	align-items: center;*/
	/*	z-index: 999;*/
	/*	.fixed-content {*/
	/*		width: 1200px;*/
	/*		height: 100%;*/
	/*		display: flex;*/
	/*		justify-content: space-between;*/
	/*		align-items: center;*/
	/*		.header-logo {*/
	/*			height: 100%;*/
	/*			img {*/
	/*				width: auto;*/
	/*				height: 100%;*/
	/*			}*/
	/*		}*/
	/*		.home-login-btn {*/
	/*			height: 100%;*/
	/*			background-color: #E21D1A;*/
	/*			border-color: #E21D1A;*/
	/*		}*/
	/*	}*/
	/*}*/
    .home-container {
        /*height: calc(100% - 84px) !important;*/
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .home-banner {
        width: 100%;
        .banner-img {
            display: block;
            width: 100%;
            max-height: 640px;
        }
        ::v-deep .swiper-pagination-bullets {
            .swiper-pagination-bullet {
                width: 30px;
                height: 3px;
                background: #fff;
                border-radius: 0;
            }
        }
        .banner-swiper-btn-prev,
        .banner-swiper-btn-next {
            width: 15%;
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
            opacity: .5;
            cursor: pointer;
            img {
                display: block;
                width: 20px;
            }
            &:hover {
                opacity: 1;
            }
        }
        .banner-swiper-btn-prev {
            left: 0;
        }
        .banner-swiper-btn-next {
            right: 0;
        }
    }
    .j-feature-box {
        /*margin: 0px auto 85px auto;*/
        margin: 0 auto;
        .j-feature-title {
            position: relative;
            display: block;
            width: 120px;
            margin: 0 auto;
            font-size: 26px;
            color: #333;
            text-align: center;
            &:after {
                content: '';
                position: absolute;
                height: 2px;
                background: #EB4D4B;
                bottom: -12px;
                left: 10px;
                right: 10px;
            }
        }
        .j-feature-list {
            margin-top: 50px;
            display: flex;
            justify-content: center;
            .j-feature-content {
                width: 370px;
                height: 267px;
                display: block;
                box-shadow: 0px 3px 10px 0px rgba(205,199,204,1);
                border-radius: 5px;
                padding: 42px 37px;
                margin: 0 22px;
                box-sizing: border-box;
                &.bg1 {
                    background: url("../../assets/images/feature-bg1.png") no-repeat 0 0;
                }
                &.bg2 {
                    background: url("../../assets/images/feature-bg2.png") no-repeat 0 0;
                }
                &.bg3 {
                    background: url("../../assets/images/feature-bg3.png") no-repeat 0 0;
                }
            }
        }
		.main-primary {
			width: 100%;
			height: 400px;
			display: flex;
			justify-content: center;
			align-items: center;
			.main-center {
				width: 970px;
				height: 270px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				.course-design-img {
					height: 100%;
					width: 376px;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.course-design-content {
					margin-left: 140px;
					height: 100%;
					.cd-title {
						margin-top: 39px;
						h4 {
							font-size: 29px;
							margin: 0;
							padding: 0;
							color: #333333;
							font-family: Microsoft Yahei,Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
						}
						p {
							margin-top: 21px;
							color: #666;
							font-size: 16px;
						}
					}
				}
				.cd-btn {
					margin-top: 63px;
					.home-primary-btn {
						background-color: #E31D1A;
						border-color: #E31D1A;
						padding: 12px 30px;
						box-shadow: 4px 2px 12px #910806;
					}
				}
			}
			&.online-shop {
				background-color: #F3F3F6;
				.main-center {
					justify-content: space-between;
					.online-shop-content {
						.cd-title {
							h4 {
								font-size: 29px;
								margin: 0;
								padding: 0;
								color: #333333;
								font-family: Microsoft Yahei,Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
							}
							p {
								margin-top: 21px;
								color: #666;
								font-size: 16px;
							}
						}
						
					}
					.online-shop-img {
						width: 376px;
						height: 282px;
						img {
							width: 100%;
							height: 100%;
						}
						
					}
				}
			}
		}
    }

	.news-content {
		background-color: #F3F3F6;
		padding: 0 240px 30px;
		display: flex;
		flex-direction: column;
		width: 100%;
		box-sizing: border-box;
		.news-header {
			width: 100%;
			background-color: #F3F3F6;
			margin: 0 auto;
			.news-item {
				display: flex;
				justify-content: center;
				align-items: center;
				hr {
					border-color: #3A9AFA;
					width: 50px;
					margin: 0;
				}
				h1 {
					/*font-size: 48px;*/
					font-size: 30px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					text-align: center;
					/*margin: 60px 20px;*/
					margin: 30px 20px;
				}
			}
			h3 {
				font-size: 24px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: rgba(102,102,102,1);
				text-align: center;
				margin: 0;
				margin-top: -30px;
			}
		}
		.news-list {
			background-color: #F3F3F6;
			margin: 20px;
			overflow: hidden;
			display: flex;
			a {
				width: calc(25% - 20px);
				margin: 0 10px;
				flex-shrink: 0;
				box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.16);
			}
			.news-item {
				display: flex;
				flex-direction: column;
				width: 100%;
				background: rgba(238,238,238,1);
				.news-cover {
					width: 100%;
					height: 0;
					padding-bottom: 60.97%;
					background: #fff;
					position: relative;
					overflow: hidden;
					img {
						max-height: 200px;
						max-width: 100%;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
				.news-item-body {
					display: flex;
					flex-direction: column;
					padding: 15px;
					flex: 1;
					color: #333;
					.title {
						text-align: center;
						display: block;
						font-size: 18px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: rgba(51,51,51,1);
						margin-bottom: 10px;
						height: 54px;
						word-break: break-all;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
					}
					.content {
						height: 80px;
						overflow : hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 4;
						-webkit-box-orient: vertical;
					}
				}
			}
			.news-items {
				position: relative;
				width: calc(25% - 20px);
				height: 369px;
				box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
				img {
					position: absolute;
					width: 100%;
					height: 100%;
				}
				.news-item-body {
					position: absolute;
					z-index: 9;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: 100%;
					width: 100%;
					.title {
						font-size:36px;
						font-family:Source Han Sans CN;
						font-weight:400;
						color:rgba(255,255,255,1);
					}
					.content {
						font-size:36px;
						font-family:Source Han Sans CN;
						font-weight:400;
						color:rgba(255,255,255,1);
					}
				}
			}
		}
	}
